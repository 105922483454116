@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";
@import "../../../../themes/static/themes/css/components/results";

#editions {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28255, 255, 255%29"></polygon></svg>')
}

#edition-menu {
  position: relative;
  background-color: #4e6350;
  padding: rem-calc(0 20);
  border-top: 1px solid $white;
  border-bottom: none;
  border-left: none;
  border-right: none;
  li {
    &.edition-search {
      position: absolute;
      right: rem-calc(15);
      padding-top: rem-calc(12);
      select {
        background-color: transparent;
        border-color: $white;
        color: $white;
        option {
          color: $black;
        }
      }
    }
    > a {
      color: $white;
      font-size: rem-calc(16);
      &:hover {
        color: $primary-color;
      }
      &:focus, &[aria-selected="true"]{
        color: $white;
        position: relative;
        &:after {
          content: ' ';
          background-color: $secondary-color;
          height: rem-calc(6);
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -6px;
        }
      }
    }
  }
}

.enologica-index {
  .tabs-content {
    border: none;
  }
}

#panel-jury {
  ul {
    list-style: none;
    margin-left: 0;
    li {
      background-image: url('/static/themes/images/check_rounded.png');
      background-repeat: no-repeat;
      line-height: rem-calc(25);
      padding-left: rem-calc(35);
      margin-top: rem-calc(10);
    }
  }
}

.overview {
  font-size: rem-calc(22);
  &__parag {
    img {
      padding: rem-calc(0 100);
      border-radius: 50%;
    }
  }
}
