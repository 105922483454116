@import "../components/settings";
@import "../components/mixins";

header {
  &.inverted {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    //margin: 0 auto;
    //max-width: $grid-row-width;
    background-color: transparent;
    border-color: rgba(255,255,255,.4);
    .nav {
      &--logo {
        svg {
          path, polygon {
            fill: $white;
          }
        }
      }
      &__item {

        &.active {

        }
      }
      &--langs {
        a {
          color: $white;
        }
      }
      &--services a svg path {
        fill: $white;
      }
      &__link {
        &--active {
          color: $white !important;
        }
        &--inverted {
          color: $white !important;
          &::before {
            background-color: $white;
          }
        }
      }
    }

    .services {
      ::-webkit-input-placeholder {
        color: $white;
      }
      ::-moz-placeholder {
        color: $white;
      }
      :-ms-input-placeholder {
        color: $white;
      }
      :-moz-placeholder {
        color: $white;
      }
      input[type=text], input[type=password] {
        border: 1px solid $white;
        color: $white;
        background-color: transparent;
      }
      .help-text {
        color: $white;

      }
      ul {
        @include menu-base;
        .button {
          color: $body-font-color;
          background-color: $white;
          padding: .5rem 1rem;
          &.primary {
            &:hover, &:focus {
              background-color: $white !important;
              color: $body-font-color !important;
            }
          }
          &.secondary {
            background-color: transparent;
            color: $white;
            border-color: $white;
          }
        }
      }
    }

    .breadcrumbs {
       color: $white;

      li{
         color: $white;
        &::after{
          color: $white;
        }
        a{
        color: $white;
      }
      }

    }
  }

}







