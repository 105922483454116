.results, .related {
  background-color: $white;
  min-height: 600px;

  .item-content {
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    position: relative;
    overflow: hidden;
    opacity: 0;

    &__image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include transition(all, 350ms, linear);
      &--blend {
        @include blender($black, .51);
      }
      a {
        display: block;
      }
    }

    &__text-info {
      color: $white;
      padding: rem-calc(6);
      position: absolute;
      text-align: center;
      line-height: 1.2;
      @include vertical-center;
      @include transition(all, 300ms);
      width: 100%;
      h5 {
        color: $white;
      }
      .text-info__content {
        &__place {
          font-weight: bold;
        }
      }
    }
    &__text-bottom {
      color: $white;
      padding: rem-calc(6);
      position: absolute;
      bottom: 20px;
      left: 30px;
      line-height: 1.2;
      @include transition(all, 300ms);
      width: 100%;
      h5 {
        color: $white;
      }
      .text-info__content {
        &__place {
          font-weight: bold;
        }
      }
    }
    &:hover {
      .thumbnail {
        transform: scale(1.1);
        &.item-content__image--blend {
          &::after {
            background-color: transparent;
          }
        }
      }
      .text-info {
        background-color: rgba(0,0,0,0.8);

        &__content {
          color: $white;
          h5 {
            color: $white;
          }
        }
      }
    }
  }
  &__paginator {
    padding: rem-calc(30);
    .pagination-previous, .pagination-next {
      @include breakpoint(medium) {
        display: none   !important;
      }
    }
  }
  &__search {
    .search_form {
      &>div {
        // padding: rem-calc(10 30);
      }

      .filter-label {
        padding: 1rem;
        @include breakpoint(medium) {
          padding: rem-calc(14) 0 0 $global-padding;
        }
      }
      .filter-items {
        padding: rem-calc(10) $global-padding 0 1rem;
      }
      &__services {
        margin-top: 1rem;
        padding: 1rem $global-padding;
      }
      &__keyword {
        padding: 1rem $global-padding;
      }

      input[type=text] {
        //border-right-color: transparent;
        //border-bottom-color: transparent;
        //border-top-color: transparent;
        //border-left-color: $secondary-color;
        border-color: $light-gray;
        // border-left-width: 2px;
        //-webkit-border-radius: 0;
        //-moz-border-radius: 0;
        // border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: rem-calc(28);
        margin-top: rem-calc(4);
        @include breakpoint(small only) {
          border-color: $light-gray;
        }
        @include breakpoint(large) {
          margin-bottom: 0;
        }
        &:hover, &:focus {
          // background-color: $light-gray;
          box-shadow: $input-shadow-focus;
        }
      }
      label.middle {
        @include breakpoint(small only) {
          margin-bottom: 0;
        }
        @include breakpoint(large) {
          margin-bottom: 0;
        }
      }
      select {
        padding-top: rem-calc(4);
        padding-bottom: rem-calc(4);
      }
    }
  }
}
.related {
  min-height: 0;
  padding-top: rem-calc(20);
  .tabs {
    border-color: transparent;
    .tabs-title {
      background-color: $white;
      border-color: transparent;
      a {
        border-bottom: 1px solid transparent;
        color: $medium-gray;
        font-size: rem-calc(16);
        margin: rem-calc(0 30);
        padding: rem-calc(0 0 10 0);
        @include transition();
        &:hover {
          border-bottom: 1px solid $light-gray;
        }
      }
      &.is-active {
        a {
          border-bottom: 1px solid $light-gray;
        }
      }
    }
    .tabs-title>a:focus, .tabs-title>a[aria-selected=true] {
      background: none;
      border-bottom: 1px solid $light-gray;
    }
  }
  .tabs-content {
    border: none;
    padding-top: rem-calc(30);
    .tabs-panel {
      padding: 0;
    }
  }
}

.detail {
  min-height: 600px;
  h1 {
    border-bottom: 1px solid $light-gray;
    margin-bottom: rem-calc(20);
  }
  label {
    display: inline-block;
    font-weight: bold;
    padding-right: rem-calc(4);
  }

  &__generic {
    .generic {
      &__info {
        padding-bottom: 1rem;
      }
      &__description {
        text-align: justify;
      }
      &__link {
        padding: rem-calc(16);
      }
    }
  }
  &__description {
    &--wine {
      border-top: 1px solid $light-gray;
      margin-top: 30px;
      padding-top: 20px;
    }
  }
  &__services {
    border-top: 1px solid $light-gray;
    margin-top: rem-calc(20);
    padding: rem-calc(20 0);
    &__service {
      img {
        max-height: rem-calc(50);
      }
    }
  }
}

.detail-page {
  background-color: $white;
  .product-detail {
    padding: $global-padding;
  }
}