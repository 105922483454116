$roboto-font-family: quote(roboto);
$roboto: $roboto-font-family , sans-serif;


// ROBOTO
@font-face {
    font-family: $roboto-font-family;
    src: url('../../../../../static/themes/fonts/roboto/Roboto-Thin.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
   src: url('../../../../../static/themes/fonts/roboto/Roboto-ThinItalic.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: $roboto-font-family;
      src: url('../../../../../static/themes/fonts/roboto/Roboto-Light.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: $roboto-font-family;
   src: url('../../../../../static/themes/fonts/roboto/Roboto-LightItalic.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: $roboto-font-family;
   src: url('../../../../../static/themes/fonts/roboto/Roboto-Regular.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $roboto-font-family;
   src: url('../../../../../static/themes/fonts/roboto/Roboto-Italic.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: $roboto-font-family;
src: url('../../../../../static/themes/fonts/roboto/Roboto-Medium.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $roboto-font-family;
src: url('../../../../../static/themes/fonts/roboto/Roboto-MediumItalic.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: $roboto-font-family;
    src: url('../../../../../static/themes/fonts/roboto/Roboto-Bold.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $roboto-font-family;
    src: url('../../../../../static/themes/fonts/roboto/Roboto-BoldItalic.woff') format('woff'),
         url('../../../../../static/themes/fonts/roboto/Roboto-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}