@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";
@import "../../../../themes/static/themes/css/components/results";

.search_form {
  &__services {
    border-top: 1px solid $light-gray;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      @include breakpoint(medium) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }

      li {
        margin-right: rem-calc(20);
        @include breakpoint(medium) {
          display: flex;
          justify-content: flex-end;
        }

        input[type=checkbox] {
          margin-bottom: 0;
        }
      }
    }
  }

  &__keyword {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }
}

.structure-detail {
  padding: 0 $global-padding;

  .generic {
    padding: 0 $global-padding;
  }

  &.territory-winery {
    h1 {
      border: none;
      line-height: 1.1;
      margin-bottom: 6px;
      @include breakpoint(medium) {
        font-size: 2.6rem;
      }
    }

    .generic {
      padding-left: 0;
      padding-right: 10px;

      &__info {
        @include breakpoint(large) {
          padding-bottom: 0;
        }

        label {
          font-weight: 400;
        }

        .inverted {
          font-weight: 700;

          label {
            font-weight: 400;
          }
        }
      }

      &__link {
        bottom: 0;
        position: absolute;
        right: 0;

        a.button {
          margin-bottom: 0;
        }
      }

      .info-content {
        border-bottom: 1px solid $light-gray;
        height: 100%;
        position: relative;
      }
    }

    .padding-top-20 {
      padding-top: 20px;
    }
  }
}


.territory-winery {
  .second-row {
    padding-top: 30px;
  }

  &__services {
    background-color: $primary-color;
    margin-bottom: 30px;
    padding: 20px;

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

    }
  }

  &__description {
    padding-left: 20px;

    p {
      line-height: 1.4;
    }
  }

  &__labels {
    background-color: #303030;
    color: #fefefe;
    padding: 10px 40px;

    h5 {
      font-weight: 500;
    }

    .item-content {
      background-color: $white;
      color: $body-font-color;
      padding: 20px;

      h4 {
        font-weight: 500;
        padding-top: 10px;
        line-height: 1;
        @include breakpoint(medium) {
          font-size: 22px;
        }
        @include breakpoint(large) {
          font-size: 25px;
        }
      }
    }

    .media-object-section {
      img {
        max-width: 48px;
        @include breakpoint(large) {
          max-width: 58px;
        }
      }

      &:first-child {
        padding-right: 10px;
        @include breakpoint(large) {
          padding-right: 20px;
        }
      }
    }

  }
}

.modal-content {
  .structure-detail {
    padding: 0;
  }
}

.bodies {
  .item-content {
    border-color: $light-gray !important;

    &__image {

    }

    &__text-info {
      opacity: 0;
      @include transition(all, 350ms, linear);
    }

    &:hover {
      .thumbnail {
        @include blender($black, .10);
      }

      .text-info {
        opacity: 1;
        background-color: transparent !important;

        &__content {
          color: $white !important;

          h5 {
            color: $white !important;
          }
        }
      }
    }
  }
}

.related {

}

.reveal.large.modal-prod-structure {
  @include breakpoint(medium) {
    max-width: 1300px;
  }
}