@import "../components/settings";

//slick dots
$slick-dot-color: transparentize($white, .7) !default;  // transparentize($primary-color, .7) !default;
$slick-dot-color-active: $white !default;  // $primary-color !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 12px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

$slick-font-family: "slick";
//@font-face {
//  font-family: $slick-font-family;
//  src: url("/static/slick-carousel/slick/fonts/slick.eot");
//  src: url("/static/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"),
//  url("/static/slick-carousel/slick/fonts/slick.woff") format("woff"),
//  url("/static/slick-carousel/slick/fonts/slick.ttf") format("truetype"),
//  url("/static/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
//  font-weight: normal;
//  font-style: normal;
//}

.slick-dots {
  position: relative;
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px 0 2px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slider-button {
  width: 30px;
  height: auto;
  cursor: pointer;
  color: $white;
  z-index: 50;
  @include vertical-center;
  i {
     width: 34px;
    height: 34px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    &::before{
      @include absolute-center;
    }
  }
  svg {
    width: 30px;
    height: auto;
    path {
      fill: $white;
    }
  }
}

.slider-prev {
  left: 20px;
}

.slider-next {

  right: 20px;
}

// Prevent load flickering
.slides {
  direction: ltr;
  &.slick-initialized {
    .slide {
      display: block;
    }
  }
  .slick-slide {
    .slide {

      position: relative;
      display: none;
      &:first-child {
        display: block;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparentize(#000000, .4);
      }
      .slide-img {
        &.blend {
          @include blender(#000000, .8);
        }
      }
    }
    &.slick-current {
      .slide::after {
        background-color: transparent;
      }
    }

    .button.clear.white {
      padding: rem-calc(10 0);
    }
  }
}

// image gallery
.image-gallery {
  .slick-slide {
    .slide::after {
        background-color: transparent;
    }
  }
  .slick-dots {
    button::before {
      color: #000000;
      font-size: 26px;
    }
    .slick-active button:before {
      color: #000000;
      opacity: 0.65;
    }
  }
}