@import "../components/mixins";
@import "../../../../../bower_components/foundation-sites/scss/components/visibility";

.full-width {
  width: 100%;
}

.center-block {
  margin: 0 auto;
  display: block;
}

.space-up {
  @include space(up);
}

.space-down {
  @include space(down);
}

.two-columns {
  @include breakpoint(medium) {
    column-count: 2;
    column-gap: 20px;
  }
}

.loader-list {
  display: none;
  width: 40px;
  height: 40px;
  margin: 40px auto 20px;
}

.logo-padding {
  padding-left: rem-calc(10);
  @include breakpoint(medium) {
    padding-left: rem-calc(40);
  }
  @include breakpoint(large) {
    padding-left: $global-padding;
  }
}

#scrollUp {
  bottom: 72px;
  right: 20px;

  background-color: rgba(10, 61, 49, 0.7);;
  border-radius: 50%;
  color: #fff;
      width: 34px;
    height: 34px;
  z-index: 450 !important;
  @include breakpoint(large) {
    bottom: 20px;
  }
  &::before {
    content: '\e004';
    font-family: $icon-monstr;
        position: absolute;
    top: 5px;
    left: 9px;

  }
}

.help-text {
  a {
    text-decoration: underline;
  }
}

.text-alert {
  color: $alert-color;
}

.ltr{
  direction: ltr;
}

.item-list {

}