@import "../components/settings";
@import "../components/mixins";


p {
  a {
    text-decoration: underline;
  }
}

.container__page {
  min-height: 600px;
  &__content {
    background-color: $white;
    max-width: $grid-row-width;
    margin: 0 auto;
    @include breakpoint(large) {
    }

    .page-content {
      &--left {
        @include breakpoint(large) {
          border-right: 1px solid $light-gray;
          padding-right: 1rem;
        }
      }
      &--right {
        @include breakpoint(large) {
          padding-left: 1rem;
        }
      }
    }
  }
}

#overlay, #overlay_mm {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.47);
  width: 100%;
  height: 100%;
  z-index: 40;
  position: fixed;
  top: 0;
  opacity: 0;
  cursor: pointer;
  @include transition(all, 150ms);
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.page-error {
  margin-top: 30px;
  text-align: center;
  h3 {
    line-height: 1.5;
    color: map-get($foundation-palette, alert);
    max-width: 600px;
    margin: 0 auto 40px;
  }
  a {

  }
}

.preload-wrapper {
  min-height: 290px;
  position: relative;
  background-color: #0a1517;
  @include breakpoint(medium) {
    min-height: 338px;
  }
  @include breakpoint(large) {
    min-height: 543px;
  }
  .preload {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
  }
  svg {
    margin: 10px auto;
    width: 45px;
    height: 45px;
    display: block;

  }
  &--smaller {

  }
}

.button.hollow.secondary, .button.hollow.grey {
  &:hover {
    background-color: $primary-color;
    border-color: scale-color($primary-color, $lightness: -15%);
    color: $white;
  }
}