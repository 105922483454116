@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";
@import "../../../../themes/static/themes/css/components/results";


.traditional-prod {
  .generic {
    &__description {
      border-bottom: 1px solid $light-gray;
    }
  }
}

.typical-wines {
  .item-content {
    i {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  .item-content:hover {
    .thumbnail {
      @include blender($black, .51);
      img {
        visibility: hidden;
      }
    }
    .text-info {
      // background-color: transparent;
      background-color: rgba(0,0,0,0.8);
      &__content {
        color: $white;
        h5 {
          color: $white;
        }
        i {
          svg {
            path {
              fill: $white;
            }
          }
        }
      }

    }
  }
}
.wine__detail {
  .thumbnail {
    padding-bottom: 1rem;
    @include breakpoint(medium) {
      padding-bottom: 0;
    }
    img {
      max-height: 300px;
      width: auto;
    }
  }
  .detail__info {
    label {
      font-size: 1rem;
    }
  }
  .related {
    border-top: 1px solid $light-gray;
  }
}
.product-detail {
  .info-content {
    padding-top: rem-calc(20);
  }
}

// doc-docg-wines
.wine-results {
  background-color: $white;
  min-height: 600px;

  .item-content {
    border-bottom: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    position: relative;
    overflow: hidden;
    opacity: 0;

    &__image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: $global-padding;
      @include transition(all, 350ms, linear);
      text-align: center;
      a {
        display: block;
      }
      img {
        max-height: 180px;
        @include breakpoint(medium) {
          max-height: 264px;
        }
      }
    }

    &__text-info {
      color: $secondary-color;
      padding: $global-padding 1rem;
      @include transition(all, 300ms);
      h5 {
        color: $secondary-color;
      }
      .text-info__content {
        &__place {
          font-weight: bold;
        }
      }
      .wine__button {
        padding-top: rem-calc(20);
      }
    }
    .wine-info {
      line-height: 1.3;
      font-weight: 300;
      a {
        font-weight: 400;
      }
    }
    label {
      display: inline-block;
      font-weight: bold;
    }
    &:hover {
    }
  }
  &__paginator {
    padding: rem-calc(30);
    .pagination-previous, .pagination-next {
      @include breakpoint(medium) {
        display: none   !important;
      }
    }
  }
  &__search {
    .search_form {
      &>div {
        // padding: rem-calc(10) $global-padding;
      }

      .filter-label {
        padding: 1rem;
        @include breakpoint(medium) {
          padding: rem-calc(14) 0 0 $global-padding;
        }
      }
      .filter-items {
        padding: rem-calc(10) $global-padding 0 1rem;
      }

      input[type=text] {
        border-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        @include breakpoint(large) {
          margin-bottom: 0;
        }
        &:hover, &:focus {
          // background-color: $light-gray;
          box-shadow: $input-shadow-focus;
        }
      }
      label.middle {
        @include breakpoint(large) {
          margin-bottom: 0;
        }
      }
      select {
        padding-top: rem-calc(4);
        padding-bottom: rem-calc(4);
      }
      &__button {
        border-bottom: 1px solid $light-gray;
        padding: rem-calc(10) $global-padding;
        button {
          margin-bottom: 0;
        }
      }
    }
  }

  &__intro {
    border-bottom: 1px solid $light-gray;
    padding: rem-calc(20) $global-padding;
  }
  &.area {
    background-image: url('/static/products/images/map_bg.gif');
    background-repeat: no-repeat;
    background-position: top left;
  }
}

.wine-areas {
  background-color: $white;
  min-height: 600px;
  padding: $global-padding;
  background-image: url('/static/products/images/map_bg.gif');
  background-repeat: no-repeat;
  background-position: top left;
  &__description {
    border-top: 1px solid $light-gray;
    margin-top: rem-calc(30);
    padding-top: rem-calc(20);
    p {
      line-height: 1.4;
      margin-bottom: rem-calc(6);
    }
  }
  .wine-areas__map {
    .mappavini {
      margin: 0 auto;
    }
  }
}
.area {
  &__list {
    font-size: rem-calc(12);
    list-style: none;
    margin-left: 0;
    width: 80%;
    @include breakpoint(medium) {
      column-count: 2;
      column-gap: 20px;
    }
    img {
      padding-right: rem-calc(4);
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
    a {
      color: $secondary-color;
      font-size: rem-calc(16);
      line-height: 1.4;
      &:hover {
        color: $primary-color;
      }
    }
  }
  &__back {
    a {
      background-image: url('/static/products/images/back_map.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 90px;
      height: 52px;
      display: inline-block;
      position: relative;
      @include breakpoint(medium) {
        width: 181px;
        height: 104px;
      }
      span {
        @include absolute-center;
        padding-bottom: rem-calc(16);
      }
    }
  }
}

.territory {
  background-color: $white;
  h1 {
    line-height: 1;
    padding-left: 30px;
    padding-top: 20px;
    @include breakpoint(large) {
      padding-top: 0;
    }
  }
  &__main {
    // padding-bottom: $global-padding;
    // padding-left: $global-padding;
  }
  &__description {
    padding: 20px 30px;
  }

  .wine-areas.black {
    background-image: none;
    background-color: #303030;
    margin-bottom: 2px;
  }
  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 600px;
  }
  &__winery-list {
    background-color: #303030;
    color: $white;
    padding: 10px 40px;
    .item-content {
      // margin: 0 2px;
      padding-bottom: 10px;
    }
    .text-info {
      &__content {
        color: $white;
        padding-top: 4px;
      }
    }
  }
  &__placeholder {
    overflow: hidden;
  }

  .teaser__simple {
    h2 {
      font-weight: 400;
    }
  }
}

.wine-territories {
  &__map {
    background-color: #303030;
    position: relative;
  }
  &__text {
    color: #ffffff;
    text-align: left;
    line-height: .8;
    padding: 0 0 20px 20px;
    @include breakpoint(large) {
      position: absolute;
      bottom: 10%;
      left: 4%;
    }
  }
}

