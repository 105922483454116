@import "../components/settings";
@import "../components/mixins";

$menu-items-padding: rem-calc(10 6);
@import "../../../../../bower_components/foundation-sites/scss/components/menu";

footer {
  .footer-w {
    background-color: $white;
    padding: rem-calc(60 0);
    &__link {
      margin: 20px auto 22px auto;
      width: 90%;  // 50%;
      h3 {
        line-height: 0.9;
        font-weight: bold;
        margin-bottom: rem-calc(2);
        @include breakpoint(medium) {
          line-height: 1.2;
          margin-bottom: rem-calc(8);
        }
      }
      .ft-link-img {
        padding-right: rem-calc(20);
        img {
          width: 116px;
        }
      }
      @include breakpoint(medium) {
        width: 600px;  // 440px;
      }
      @include breakpoint(large) {
        width: 700px;
      }
    }
    .TA_excellent {
      > img {
        display: block;
      }
      .widEXC {
        margin: 0 auto 10px auto  !important;
        position: relative;
      }
    }
  }
  .footer-menu {
    @include menu-base;
    margin-bottom: rem-calc(40);

    a {
      color: $dark-gray;
      display: inline-block;
      text-transform: uppercase;
      @include transition(all, 120ms);
      &:hover, &:active {
        color: $primary-color;
      }
    }
  }

  .footer-text {
    color: $dark-gray;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(22);
    padding: 0 $global-padding;
    text-align: center;
    @include breakpoint(large) {
      padding: 0 1rem;
    }
    p {
      margin: 0;
    }
  }
}


