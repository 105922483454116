@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";

.events-index {
  background-color: $white;
  min-height: 600px;
  &__list {
    border-top: 1px solid $light-gray;


  }
  .year-section {
      margin-top: rem-calc(30);
      border-bottom: 1px solid #d3d3d3;
      margin-bottom: 0;
      padding-bottom: rem-calc(10);
    }
  .event {
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: rem-calc(20);
    &__date {
      font-weight: 600;
    }
    &__image {
      img {
        border-radius: 50%;
        margin: rem-calc(20) 0;
      }
    }
    h4 {
      line-height: rem-calc(42);
      a {
        color: $black;
      }
    }
  }
}