@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";
@import "../../../../themes/static/themes/css/components/results";

.news-index {
  form {
    display: inline-block;
    select#id_category {
      max-width: rem-calc(150);
    }
  }
  &__list {
    border-top: 1px solid $light-gray;
  }
  .article {
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: $global-padding;
    &__date {
      font-weight: 600;
    }
    &__image {
      img {
        border-radius: 50%;
        margin: rem-calc(20) 0;
      }
    }
    h2 {
      line-height: rem-calc(42);
      a {
        color: $black;
      }
    }
  }

  .button.hollow.grey {
    @include breakpoint(large) {
      min-width: rem-calc(200);
    }
  }

  &__filter {
    .search_form__generic {
      min-width: 400px;
    }
    .filter-label {
      padding-top: rem-calc(6);
    }
    padding: rem-calc(20 0);
    select {
      min-width: rem-calc(200);
    }
  }

  .slider {
    &__news {
      &__text {
        background-color: transparent;
        color: $white;
        h2 {
          color: $white;
        }
        h5 {
          margin-bottom: rem-calc(2);
          text-transform: uppercase;
        }
        .bar.center {
          margin: 4px auto;
        }
      }
    }
    .slide {
      &__date {
        padding-top: rem-calc(10);
      }
    }
  }
}

.news-detail {
  background-color: $white;
  img {
    width: 100%;
  }
  &__content {
    padding: $global-padding;
  }
  &__gallery {
    padding: rem-calc(10) $global-padding;
    .slide__content {
      margin: rem-calc(0 20);
      padding-bottom: rem-calc(10);
    }
  }
  &__link {
    font-size: rem-calc(26);
  }
}

.news-modal {
  .modal-content {
    padding: 0;
  }
}

.latest-news {
  h2.title {
    font-weight: 100;
  }
  .article__title h2 {
    font-size: rem-calc(18);
    a {
      color: $black;
    }
  }
  img {
    border-radius: 50%;
    max-width: 60%;
  }
  .article__button {
    margin-top: rem-calc(15);
  }
  .large-3 {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    border-right: 1px solid $light-gray;
    &:last-child {
      border: 0;
    }
  }
}