@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Thin.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  src: url("../../../../../static/themes/fonts/roboto/Roboto-ThinItalic.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Light.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-LightItalic.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Regular.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Italic.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Medium.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-MediumItalic.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-Bold.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "roboto";
  src: url("../../../../../static/themes/fonts/roboto/Roboto-BoldItalic.woff") format("woff"), url("../../../../../static/themes/fonts/roboto/Roboto-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "iconmonstr-iconic-font";
  src: url("../../../../../static/themes/fonts/iconmonstr/iconmonstr-iconic-font.woff2?v=1.3.0") format("woff2"), url("../../../../../static/themes/fonts/iconmonstr/iconmonstr-iconic-font.woff?v=1.3.0") format("woff"), url("../../../../../static/themes/fonts/iconmonstr/iconmonstr-iconic-font.ttf?v=1.3.0") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.im {
  display: inline-block;
  font: normal normal normal 24px/1 'iconmonstr-iconic-font';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.im-angle-right:before {
  content: '\e001';
}

.im-angle-left:before {
  content: '\e002';
}

.im-angle-down:before {
  content: '\e003';
}

.im-angle-up:before {
  content: '\e004';
}

.im-angle-right-circle:before {
  content: '\e005';
}

.im-angle-left-circle:before {
  content: '\e006';
}

.im-angle-down-circle:before {
  content: '\e007';
}

.im-angle-up-circle:before {
  content: '\e008';
}

.im-arrow-right:before {
  content: '\e009';
}

.im-arrow-left:before {
  content: '\e00a';
}

.im-arrow-down:before {
  content: '\e00b';
}

.im-arrow-up:before {
  content: '\e00c';
}

.im-arrow-right-circle:before {
  content: '\e00d';
}

.im-arrow-left-circle:before {
  content: '\e00e';
}

.im-arrow-down-circle:before {
  content: '\e00f';
}

.im-arrow-up-circle:before {
  content: '\e010';
}

.im-care-right:before {
  content: '\e011';
}

.im-care-left:before {
  content: '\e012';
}

.im-care-down:before {
  content: '\e013';
}

.im-care-up:before {
  content: '\e014';
}

.im-forbidden:before {
  content: '\e015';
}

.im-printer:before {
  content: '\e016';
}

.im-menu:before {
  content: '\e017';
}

.im-menu-list:before {
  content: '\e018';
}

.im-quote-left:before {
  content: '\e019';
}

.im-quote-right:before {
  content: '\e01a';
}

.im-bell:before {
  content: '\e01b';
}

.im-bell-off:before {
  content: '\e01c';
}

.im-bookmark:before {
  content: '\e01d';
}

.im-briefcase:before {
  content: '\e01e';
}

.im-calendar:before {
  content: '\e01f';
}

.im-photo-camera:before {
  content: '\e020';
}

.im-video-camera:before {
  content: '\e021';
}

.im-microphone:before {
  content: '\e022';
}

.im-check-mark:before {
  content: '\e023';
}

.im-check-mark-circle:before {
  content: '\e024';
}

.im-check-square-o:before {
  content: '\e025';
}

.im-check-square:before {
  content: '\e026';
}

.im-check-square-i:before {
  content: '\e027';
}

.im-square-o:before {
  content: '\e028';
}

.im-radio-button-circle-o:before {
  content: '\e029';
}

.im-radio-button-circle:before {
  content: '\e02a';
}

.im-circle-o:before {
  content: '\e02b';
}

.im-clock-o:before {
  content: '\e02c';
}

.im-cloud:before {
  content: '\e02d';
}

.im-cloud-download:before {
  content: '\e02e';
}

.im-cloud-upload:before {
  content: '\e02f';
}

.im-code:before {
  content: '\e030';
}

.im-speech-bubble:before {
  content: '\e031';
}

.im-speech-bubble-comment:before {
  content: '\e032';
}

.im-speech-bubble-comments:before {
  content: '\e033';
}

.im-copy:before {
  content: '\e034';
}

.im-credit-card:before {
  content: '\e035';
}

.im-crown:before {
  content: '\e036';
}

.im-database:before {
  content: '\e037';
}

.im-computer:before {
  content: '\e038';
}

.im-download:before {
  content: '\e039';
}

.im-upload:before {
  content: '\e03a';
}

.im-pencil:before {
  content: '\e03b';
}

.im-edit-off:before {
  content: '\e03c';
}

.im-play:before {
  content: '\e03d';
}

.im-pause:before {
  content: '\e03e';
}

.im-stop:before {
  content: '\e03f';
}

.im-eject:before {
  content: '\e040';
}

.im-previous:before {
  content: '\e041';
}

.im-next:before {
  content: '\e042';
}

.im-loop:before {
  content: '\e043';
}

.im-random:before {
  content: '\e044';
}

.im-menu-dot-h:before {
  content: '\e045';
}

.im-menu-dot-v:before {
  content: '\e046';
}

.im-mail:before {
  content: '\e047';
}

.im-info:before {
  content: '\e048';
}

.im-warning:before {
  content: '\e049';
}

.im-question:before {
  content: '\e04a';
}

.im-link:before {
  content: '\e04b';
}

.im-unlink:before {
  content: '\e04c';
}

.im-external-link:before {
  content: '\e04d';
}

.im-eye:before {
  content: '\e04e';
}

.im-eye-off:before {
  content: '\e04f';
}

.im-file:before {
  content: '\e050';
}

.im-file-o:before {
  content: '\e051';
}

.im-files-o:before {
  content: '\e052';
}

.im-video:before {
  content: '\e053';
}

.im-audio:before {
  content: '\e054';
}

.im-picture-o:before {
  content: '\e055';
}

.im-flag:before {
  content: '\e056';
}

.im-folder:before {
  content: '\e057';
}

.im-folder-open:before {
  content: '\e058';
}

.im-smiley-o:before {
  content: '\e059';
}

.im-frown-o:before {
  content: '\e05a';
}

.im-gear:before {
  content: '\e05b';
}

.im-globe:before {
  content: '\e05c';
}

.im-heart:before {
  content: '\e05d';
}

.im-home:before {
  content: '\e05e';
}

.im-inbox:before {
  content: '\e05f';
}

.im-key:before {
  content: '\e060';
}

.im-lock:before {
  content: '\e061';
}

.im-lock-open:before {
  content: '\e062';
}

.im-task-o:before {
  content: '\e063';
}

.im-filter:before {
  content: '\e064';
}

.im-light-bulb:before {
  content: '\e065';
}

.im-flash:before {
  content: '\e066';
}

.im-map-o:before {
  content: '\e067';
}

.im-location:before {
  content: '\e068';
}

.im-maximize:before {
  content: '\e069';
}

.im-minimize:before {
  content: '\e06a';
}

.im-fullscreen:before {
  content: '\e06b';
}

.im-mobile:before {
  content: '\e06c';
}

.im-phone:before {
  content: '\e06d';
}

.im-coin:before {
  content: '\e06e';
}

.im-banknote:before {
  content: '\e06f';
}

.im-paper-clip:before {
  content: '\e070';
}

.im-bar-chart:before {
  content: '\e071';
}

.im-plus:before {
  content: '\e072';
}

.im-minus:before {
  content: '\e073';
}

.im-plus-circle:before {
  content: '\e074';
}

.im-minus-circle:before {
  content: '\e075';
}

.im-undo:before {
  content: '\e076';
}

.im-redo:before {
  content: '\e077';
}

.im-rocket:before {
  content: '\e078';
}

.im-rss:before {
  content: '\e079';
}

.im-magnifier:before {
  content: '\e07a';
}

.im-magnifier-plus:before {
  content: '\e07b';
}

.im-magnifier-minus:before {
  content: '\e07c';
}

.im-share:before {
  content: '\e07d';
}

.im-shield:before {
  content: '\e07e';
}

.im-shopping-cart:before {
  content: '\e07f';
}

.im-sign-in:before {
  content: '\e080';
}

.im-sign-out:before {
  content: '\e081';
}

.im-spinner:before {
  content: '\e082';
}

.im-star:before {
  content: '\e083';
}

.im-star-half:before {
  content: '\e084';
}

.im-star-o:before {
  content: '\e085';
}

.im-sync:before {
  content: '\e086';
}

.im-table:before {
  content: '\e087';
}

.im-window-o:before {
  content: '\e088';
}

.im-windows-o:before {
  content: '\e089';
}

.im-thumb-up:before {
  content: '\e08a';
}

.im-thumb-down:before {
  content: '\e08b';
}

.im-x-mark:before {
  content: '\e08c';
}

.im-x-mark-circle:before {
  content: '\e08d';
}

.im-trash-can:before {
  content: '\e08e';
}

.im-user-male:before {
  content: '\e08f';
}

.im-user-female:before {
  content: '\e090';
}

.im-user-circle:before {
  content: '\e091';
}

.im-users:before {
  content: '\e092';
}

.im-volume:before {
  content: '\e093';
}

.im-volume-off:before {
  content: '\e094';
}

.im-wifi:before {
  content: '\e095';
}

.im-tools:before {
  content: '\e096';
}

.im-dashboard:before {
  content: '\e097';
}

.im-archive:before {
  content: '\e098';
}

.im-save:before {
  content: '\e099';
}

.im-floppy-disk:before {
  content: '\e09a';
}

.im-sitemap:before {
  content: '\e09b';
}

.im-toggle:before {
  content: '\e09c';
}

.im-tag:before {
  content: '\e09d';
}

.im-tags:before {
  content: '\e09e';
}

.im-wizard:before {
  content: '\e09f';
}

.im-book:before {
  content: '\e0a0';
}

.im-fire:before {
  content: '\e0a1';
}

.im-id-card:before {
  content: '\e0a2';
}

.im-note-o:before {
  content: '\e0a3';
}

.im-control-panel:before {
  content: '\e0a4';
}

.im-facebook:before {
  content: '\e0a5';
}

.im-facebook-like:before {
  content: '\e0a6';
}

.im-twitter:before {
  content: '\e0a7';
}

.im-amazon:before {
  content: '\e0a8';
}

.im-android-os:before {
  content: '\e0a9';
}

.im-apple-os:before {
  content: '\e0aa';
}

.im-windows-os:before {
  content: '\e0ab';
}

.im-linux-os:before {
  content: '\e0ac';
}

.im-chrome:before {
  content: '\e0ad';
}

.im-ie:before {
  content: '\e0ae';
}

.im-edge:before {
  content: '\e0af';
}

.im-firefox:before {
  content: '\e0b0';
}

.im-safari:before {
  content: '\e0b1';
}

.im-opera:before {
  content: '\e0b2';
}

.im-behance:before {
  content: '\e0b3';
}

.im-blogger:before {
  content: '\e0b4';
}

.im-flickr:before {
  content: '\e0b5';
}

.im-github:before {
  content: '\e0b6';
}

.im-google-plus:before {
  content: '\e0b7';
}

.im-instagram:before {
  content: '\e0b8';
}

.im-linkedin:before {
  content: '\e0b9';
}

.im-pinterest:before {
  content: '\e0ba';
}

.im-skype:before {
  content: '\e0bb';
}

.im-snapchat:before {
  content: '\e0bc';
}

.im-soundcloud:before {
  content: '\e0bd';
}

.im-stackoverflow:before {
  content: '\e0be';
}

.im-stumbleupon:before {
  content: '\e0bf';
}

.im-tumblr:before {
  content: '\e0c0';
}

.im-xing:before {
  content: '\e0c1';
}

.im-youtube:before {
  content: '\e0c2';
}

.im-reddit:before {
  content: '\e0c3';
}

.im-vimeo:before {
  content: '\e0c4';
}

.im-vk:before {
  content: '\e0c5';
}

.im-whatsapp:before {
  content: '\e0c6';
}

.im-paypal:before {
  content: '\e0c7';
}

.im-twitch:before {
  content: '\e0c8';
}

.im-drop:before {
  content: '\e0c9';
}

.im-sun:before {
  content: '\e0ca';
}

.im-certificate-o:before {
  content: '\e0cb';
}

.im-graduation-hat:before {
  content: '\e0cc';
}

.im-store:before {
  content: '\e0cd';
}

.im-pin:before {
  content: '\e0ce';
}

.im-navigation:before {
  content: '\e0cf';
}

.im-keyboard:before {
  content: '\e0d0';
}

.im-cursor:before {
  content: '\e0d1';
}

.im-monitor-o:before {
  content: '\e0d2';
}

.im-laptop-o:before {
  content: '\e0d3';
}

.im-power:before {
  content: '\e0d4';
}

.im-pie-chart:before {
  content: '\e0d5';
}

.im-line-chart-up:before {
  content: '\e0d6';
}

.im-clock:before {
  content: '\e0d7';
}

.im-flip-chart-o:before {
  content: '\e0d8';
}

.im-gift:before {
  content: '\e0d9';
}

.im-leaf:before {
  content: '\e0da';
}

.im-bug:before {
  content: '\e0db';
}

.im-coffee:before {
  content: '\e0dc';
}

.im-diamond-o:before {
  content: '\e0dd';
}

.im-bell-active:before {
  content: '\e0de';
}

.im-history:before {
  content: '\e0df';
}

.im-gamepad:before {
  content: '\e0e0';
}

.im-binoculars:before {
  content: '\e0e1';
}

.im-paperplane:before {
  content: '\e0e2';
}

.im-wrench:before {
  content: '\e0e3';
}

.im-newspaper-o:before {
  content: '\e0e4';
}

.im-lifebuoy:before {
  content: '\e0e5';
}

.im-fingerprint:before {
  content: '\e0e6';
}

.im-date-o:before {
  content: '\e0e7';
}

.im-network:before {
  content: '\e0e8';
}

.im-target:before {
  content: '\e0e9';
}

.im-user-settings:before {
  content: '\e0ea';
}

.im-radio:before {
  content: '\e0eb';
}

.im-bank:before {
  content: '\e0ec';
}

.im-calculator:before {
  content: '\e0ed';
}

.im-battery-empty:before {
  content: '\e0ee';
}

.im-battery:before {
  content: '\e0ef';
}

.im-battery-full:before {
  content: '\e0f0';
}

.im-check-mark-circle-o:before {
  content: '\e0f1';
}

.im-x-mark-circle-o:before {
  content: '\e0f2';
}

.im-cube:before {
  content: '\e0f3';
}

.im-cubes:before {
  content: '\e0f4';
}

.im-warning-circle:before {
  content: '\e0f5';
}

.im-timer:before {
  content: '\e0f6';
}

.im-hashtag:before {
  content: '\e0f7';
}

.im-pointer:before {
  content: '\e0f8';
}

.im-paintbrush:before {
  content: '\e0f9';
}

.im-server:before {
  content: '\e0fa';
}

.im-car:before {
  content: '\e0fb';
}

.im-edit:before {
  content: '\e0fc';
}

.im-flask:before {
  content: '\e0fd';
}

.im-language:before {
  content: '\e0fe';
}

.im-anchor:before {
  content: '\e0ff';
}

.im-trophy:before {
  content: '\e100';
}

.im-umbrella:before {
  content: '\e101';
}

.im-cc-amex:before {
  content: '\e102';
}

.im-cc-visa:before {
  content: '\e103';
}

.im-cc-mastercard:before {
  content: '\e104';
}

.im-cc-paypal:before {
  content: '\e105';
}

.im-cc-amazon:before {
  content: '\e106';
}

.im-cc-bitcoin:before {
  content: '\e107';
}

.im-direction:before {
  content: '\e108';
}

.im-gift-card:before {
  content: '\e109';
}

.im-newsletter:before {
  content: '\e10a';
}

.im-ribbon:before {
  content: '\e10b';
}

.im-award:before {
  content: '\e10c';
}

.im-first-aid:before {
  content: '\e10d';
}

.im-cookie:before {
  content: '\e10e';
}

.im-data:before {
  content: '\e10f';
}

.im-data-delete:before {
  content: '\e110';
}

.im-data-validate:before {
  content: '\e111';
}

.im-import:before {
  content: '\e112';
}

.im-export:before {
  content: '\e113';
}

.im-reset:before {
  content: '\e114';
}

.im-radar:before {
  content: '\e115';
}

.im-idea:before {
  content: '\e116';
}

.im-pizza:before {
  content: '\e117';
}

.im-truck:before {
  content: '\e118';
}

.im-support:before {
  content: '\e119';
}

.im-color-fan:before {
  content: '\e11a';
}

.im-layer:before {
  content: '\e11b';
}

.im-factory:before {
  content: '\e11c';
}

.im-apartment:before {
  content: '\e11d';
}

.im-plane:before {
  content: '\e11e';
}

.im-headphones:before {
  content: '\e11f';
}

.im-megaphone:before {
  content: '\e120';
}

.im-folder-add:before {
  content: '\e121';
}

.im-barcode:before {
  content: '\e122';
}

.im-accessibility:before {
  content: '\e123';
}

.im-plugin:before {
  content: '\e124';
}

.im-pulse:before {
  content: '\e125';
}

.im-spotify:before {
  content: '\e126';
}

.im-dribbble:before {
  content: '\e127';
}

.im-google-play:before {
  content: '\e128';
}

.im-facebook-messenger:before {
  content: '\e129';
}

.im-wechat:before {
  content: '\e12a';
}

.im-line:before {
  content: '\e12b';
}

.im-git:before {
  content: '\e12c';
}

.results, .related {
  background-color: #fefefe;
  min-height: 600px;
}

.results .item-content, .related .item-content {
  border-bottom: 1px solid #fefefe;
  border-right: 1px solid #fefefe;
  position: relative;
  overflow: hidden;
  opacity: 0;
}

.results .item-content__image, .related .item-content__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition-property: all;
  transition-duration: 350ms;
  transition-timing-function: linear;
  transition-delay: 0ms;
}

.results .item-content__image--blend::after, .related .item-content__image--blend::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 35, 0.49);
}

.results .item-content__image a, .related .item-content__image a {
  display: block;
}

.results .item-content__text-info, .related .item-content__text-info {
  color: #fefefe;
  padding: 0.375rem;
  position: absolute;
  text-align: center;
  line-height: 1.2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: linear;
  transition-delay: 0ms;
  width: 100%;
}

.results .item-content__text-info h5, .related .item-content__text-info h5 {
  color: #fefefe;
}

.results .item-content__text-info .text-info__content__place, .related .item-content__text-info .text-info__content__place {
  font-weight: bold;
}

.results .item-content__text-bottom, .related .item-content__text-bottom {
  color: #fefefe;
  padding: 0.375rem;
  position: absolute;
  bottom: 20px;
  left: 30px;
  line-height: 1.2;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: linear;
  transition-delay: 0ms;
  width: 100%;
}

.results .item-content__text-bottom h5, .related .item-content__text-bottom h5 {
  color: #fefefe;
}

.results .item-content__text-bottom .text-info__content__place, .related .item-content__text-bottom .text-info__content__place {
  font-weight: bold;
}

.results .item-content:hover .thumbnail, .related .item-content:hover .thumbnail {
  transform: scale(1.1);
}

.results .item-content:hover .thumbnail.item-content__image--blend::after, .related .item-content:hover .thumbnail.item-content__image--blend::after {
  background-color: transparent;
}

.results .item-content:hover .text-info, .related .item-content:hover .text-info {
  background-color: rgba(0, 0, 0, 0.8);
}

.results .item-content:hover .text-info__content, .related .item-content:hover .text-info__content {
  color: #fefefe;
}

.results .item-content:hover .text-info__content h5, .related .item-content:hover .text-info__content h5 {
  color: #fefefe;
}

.results__paginator, .related__paginator {
  padding: 1.875rem;
}

@media print, screen and (min-width: 40em) {
  .results__paginator .pagination-previous, .results__paginator .pagination-next, .related__paginator .pagination-previous, .related__paginator .pagination-next {
    display: none   !important;
  }
}

.results__search .search_form .filter-label, .related__search .search_form .filter-label {
  padding: 1rem;
}

@media print, screen and (min-width: 40em) {
  .results__search .search_form .filter-label, .related__search .search_form .filter-label {
    padding: 0.875rem 0 0 2.5rem;
  }
}

.results__search .search_form .filter-items, .related__search .search_form .filter-items {
  padding: 0.625rem 2.5rem 0 1rem;
}

.results__search .search_form__services, .related__search .search_form__services {
  margin-top: 1rem;
  padding: 1rem 2.5rem;
}

.results__search .search_form__keyword, .related__search .search_form__keyword {
  padding: 1rem 2.5rem;
}

.results__search .search_form input[type=text], .related__search .search_form input[type=text] {
  border-color: #d3d3d3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 1.75rem;
  margin-top: 0.25rem;
}

@media print, screen and (max-width: 39.99875em) {
  .results__search .search_form input[type=text], .related__search .search_form input[type=text] {
    border-color: #d3d3d3;
  }
}

@media print, screen and (min-width: 64em) {
  .results__search .search_form input[type=text], .related__search .search_form input[type=text] {
    margin-bottom: 0;
  }
}

.results__search .search_form input[type=text]:hover, .results__search .search_form input[type=text]:focus, .related__search .search_form input[type=text]:hover, .related__search .search_form input[type=text]:focus {
  box-shadow: 0 0 5px #676767;
}

@media print, screen and (max-width: 39.99875em) {
  .results__search .search_form label.middle, .related__search .search_form label.middle {
    margin-bottom: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .results__search .search_form label.middle, .related__search .search_form label.middle {
    margin-bottom: 0;
  }
}

.results__search .search_form select, .related__search .search_form select {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.related {
  min-height: 0;
  padding-top: 1.25rem;
}

.related .tabs {
  border-color: transparent;
}

.related .tabs .tabs-title {
  background-color: #fefefe;
  border-color: transparent;
}

.related .tabs .tabs-title a {
  border-bottom: 1px solid transparent;
  color: #676767;
  font-size: 1rem;
  margin: 0 1.875rem;
  padding: 0 0 0.625rem 0;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 0ms;
}

.related .tabs .tabs-title a:hover {
  border-bottom: 1px solid #d3d3d3;
}

.related .tabs .tabs-title.is-active a {
  border-bottom: 1px solid #d3d3d3;
}

.related .tabs .tabs-title > a:focus, .related .tabs .tabs-title > a[aria-selected=true] {
  background: none;
  border-bottom: 1px solid #d3d3d3;
}

.related .tabs-content {
  border: none;
  padding-top: 1.875rem;
}

.related .tabs-content .tabs-panel {
  padding: 0;
}

.detail {
  min-height: 600px;
}

.detail h1 {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 1.25rem;
}

.detail label {
  display: inline-block;
  font-weight: bold;
  padding-right: 0.25rem;
}

.detail__generic .generic__info {
  padding-bottom: 1rem;
}

.detail__generic .generic__description {
  text-align: justify;
}

.detail__generic .generic__link {
  padding: 1rem;
}

.detail__description--wine {
  border-top: 1px solid #d3d3d3;
  margin-top: 30px;
  padding-top: 20px;
}

.detail__services {
  border-top: 1px solid #d3d3d3;
  margin-top: 1.25rem;
  padding: 1.25rem 0;
}

.detail__services__service img {
  max-height: 3.125rem;
}

.detail-page {
  background-color: #fefefe;
}

.detail-page .product-detail {
  padding: 2.5rem;
}

#editions {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28255, 255, 255%29"></polygon></svg>');
}

.cartacanta-index #edition-menu {
  position: relative;
  background-color: #5eb1a2;
  padding: 0 1.25rem;
  border-top: 1px solid #fefefe;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

@media print, screen and (max-width: 39.99875em) {
  .cartacanta-index #edition-menu {
    padding-bottom: 50px;
  }
}

.cartacanta-index #edition-menu li.edition-search {
  position: absolute;
  right: 0.9375rem;
  padding-top: 0.75rem;
}

.cartacanta-index #edition-menu li.edition-search select {
  background-color: transparent;
  border-color: #fefefe;
  color: #fefefe;
}

.cartacanta-index #edition-menu li.edition-search select option {
  color: #232323;
}

@media print, screen and (max-width: 39.99875em) {
  .cartacanta-index #edition-menu li.edition-search {
    right: auto;
    left: 30px;
    width: 90%;
  }
}

.cartacanta-index #edition-menu li > a {
  color: #fefefe;
  font-size: 1rem;
}

.cartacanta-index #edition-menu li > a:hover {
  color: #c4bb5c;
}

.cartacanta-index #edition-menu li > a:focus, .cartacanta-index #edition-menu li > a[aria-selected="true"] {
  color: #fefefe;
  position: relative;
}

.cartacanta-index #edition-menu li > a:focus:after, .cartacanta-index #edition-menu li > a[aria-selected="true"]:after {
  content: ' ';
  background-color: #303030;
  height: 0.375rem;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -6px;
}

@media print, screen and (max-width: 39.99875em) {
  .cartacanta-index #edition-menu .tabs-title {
    float: none;
  }
}

.cartacanta-index .tabs-content {
  border: none;
}

#panel-jury ul {
  list-style: none;
  margin-left: 0;
}

#panel-jury ul li {
  background-image: url("/static/themes/images/check_rounded.png");
  background-repeat: no-repeat;
  line-height: 1.5625rem;
  padding-left: 2.1875rem;
  margin-top: 0.625rem;
}

.overview {
  font-size: 1.375rem;
}

.overview__parag img {
  padding: 0 6.25rem;
  border-radius: 50%;
}
