@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";

.contacts {
  background-color: $white;
  min-height: 600px;
  padding: 0 $global-padding;
  p, .coordinates {
    line-height: rem-calc(21);
  }
  .map-button-text {
    vertical-align: text-bottom;
    display: inline-block;
    margin-top: rem-calc(-8);
  }
  &__info {
    border-top: 1px solid $light-gray;
    padding: rem-calc(20 0);
    margin-right: rem-calc(30);
    h5 {
      font-weight: 700;
    }
    .button {
      text-transform: uppercase;
    }
    @include breakpoint(large) {
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }
  &__form {
    border-top: 1px solid $light-gray;
    padding-top: rem-calc(20);
    @include breakpoint(large) {
      border-top: none;
      padding-top: 0;
    }
    .button {
      min-width: rem-calc(200);
    }
    h5 {
      font-weight: 700;
    }
    label.form-field {
      margin-bottom: rem-calc(15);

      select {
        margin-bottom: 0;
      }
    }
    .privacy {
      margin-top: rem-calc(30);
    }
    .submit {
      margin-top: rem-calc(30);
    }
  }
  input, select, textarea {
    border-radius: 5px;
    border-color: $light-gray;
    margin-bottom: rem-calc(6);
  }
  svg {
    width: rem-calc(10);
    margin-left: rem-calc(8);
    vertical-align: middle;
    path {
      fill: $white;
    }
  }
  ul {
    display: inline-block;
    list-style: none;
    &#id_gender {
      li {
        display: inline-block;
        &:first-child {
          display: none;
        }
      }
    }
  }
  form {
    margin-top: rem-calc(20);
  }
  .small-1 {
    width: 3%;
  }
  button[type="submit"] {
    margin-top: rem-calc(20);
  }
}