body {
  background-color: #ffffff;
}

.sync-session-monitor {
  *,
  *::before,
  *::after {
    box-sizing: content-box !important;
    font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Lucida Grande", "DejaVu Sans", "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  }

  .actions {
    label {
      color: #999;
    }

    select {
      width: auto !important;
    }

    button {
      box-sizing: border-box !important;
    }
  }

  h1 {
    margin: 0 0 20px;
    font-weight: 300;
    font-size: 20px;
    color: #666;
    line-height: unset !important;
  }

  .base-info {
    background-color: #eeeeee;
    padding: 10px;

  }

  .sync-actions {
  }

  .module {
    h2 {
      margin-top: 10px;
    }

    .info-row {
      overflow: hidden;
      width: 100%;
      line-height: 30px;

      .label {
        font-weight: bold;
        float: left;
      }

      .value {
        float: left;
        margin-left: 20px;
      }
    }
  }

  #sync-log-messages-container {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li.close {
        ul {
          display: none;
        }
      }

    }

    .log-messages {

      .log-content {
        overflow: hidden;
        width: 100%;

        > .log-column {
          float: left;

          &:last-child {
            float: right;
          }
        }

        > .log-message {
          font-weight: bold;
        }
      }

      .log-row {
        &.message-level-0 {
          margin: 10px 0 0 0;
          padding: 10px;
          background-color: #eeeeee;
          border: 1px solid #ddd;

          font-weight: bold;

          .log-messages {
            margin-top: 10px;
          }
        }

        &.message-level-1 {
          font-weight: normal;
          padding: 10px 0 5px;
          border-top: 1px dashed #aaa;

          &.message-status-info {
            color: green;
          }

          &.message-status-error {
            color: red;
          }

          &.message-status-warning {
            color: darkorange;
          }

          .log-messages {
            margin-top: 10px;
            border-left: 1px dashed #aaa;
            margin-left: 5px;
            background-color: #e4e4e4;
          }
        }

        &.message-level-2 {
          font-weight: normal;
          padding: 5px;

          &.message-status-info {
            color: green;
          }

          &.message-status-error {
            color: red;
          }

          &.message-status-warning {
            color: darkorange;
          }

        }

        &.message-level-3 {
          background-color: #bbbbbb;
          font-weight: normal;

          &.message-status-info {
            color: green;
          }

          &.message-status-error {
            color: red;
          }

          &.message-status-warning {
            color: darkorange;
          }

        }
      }
    }
  }
}

