@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";
@import "../../../../bower_components/foundation-sites/scss/components/_menu.scss";

.auth{
  min-height: 600px;
  background-color: $white;
  ul{
    @include menu-base();
    @include menu-direction(vertical);
    margin-bottom: rem-calc(12);
  }
  .login{
    border: 1px solid $light-gray;
    padding: rem-calc(20 30);
    box-shadow: 0 4px 22px -5px #a7a7a7;
    border-radius: 30px;
  }
}

