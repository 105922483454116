@import "../../components/settings";
@import "../../components/mixins";


.box-series {
  &__content {
    position:relative;

    @include xy-gutters((
          small: 2px,
          medium: 2px
    ), margin, right bottom);
  }
  .text-w {
    @include breakpoint(large) {
      bottom: 20px;
      position: absolute;
      left: 20px;
      width: 60%;
    }


    &__subtitle {
      background-color: $primary-color;
      text-align: center;
      width: 32%;
      h3 {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        line-height: 0.8;
        margin: 0;
        padding: rem-calc(10 0);
        text-align: center;
        text-transform: uppercase;
      }
    }
    &__box {
      background-color: $white;
      padding: rem-calc(10 16);
      .box-logo {
        img {
          max-height: 90px;
          padding: rem-calc(10 0)
        }
      }
    }
    &__description {

    }
  }
  .button.hollow.grey {
    margin-right: rem-calc(8);
    text-transform: uppercase;
    &:hover {
      background-color: $primary-color;
      border-color: scale-color($primary-color, $lightness: -15%);
      color: $white;
    }
  }
}

.slider {
  @include space(down);
  .slider-button {
    @include breakpoint(medium down) {
    //  display: none !important;
    }
  }
  .slick-dots {
    @include breakpoint(large) {
    //  display: none !important;
    }
  }
  &__simple, &__news {
    &__slide {
      position: relative;
    }
    &__img {
      &.blend {
        @include blender(#000000, 0.7);
      }
      img {
        display: inline-block;
      }
    }
    &__text {
      text-align: left;
      z-index: 9900;

      a {
        color: $white;
        text-decoration: underline;
        &:hover {
          color: #ffffff;
        }
      }
      .slieder-animate {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        @include breakpoint(large) {
          padding: rem-calc(20 50);
        }
      }

      .bar {
        background-color: $white;
        width: 16%;
        height: 3px;
        margin-bottom: rem-calc(14);
      }
      h2 {
        color: $white;
        font-family: $roboto;
        line-height: 1;
        margin-bottom: rem-calc(16);
        @include breakpoint(large){
        }
      }
      .button{
        margin-bottom: 10px;
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
      }
      &.center--left, &.center-- {
        @include breakpoint(large) {
          // @include vertical-center;
          position: absolute;
          top: 50%;
          left: 0;
          max-width: 80%;
        }

      }
      &.center--center {
        @include breakpoint(large) {
          @include absolute-center;
          max-width: 50%;
        }

      }
      &.center--right {
        @include breakpoint(large) {
          @include vertical-center;
          right: 0;
          max-width: 50%;
        }
      }
      .button.primary.hollow {
      }

      &--white {
        color: $white;
        a {
          color: $white;
          text-decoration: underline;
          &:hover {
            color: #ffffff;
          }
        }
        h2 {
          color: $white;
        }
        @include breakpoint(large) {

          @include headers() {
            color: $white;
          }
          .button.white.clear {
            &:hover {
              background-color: scale-color($primary-color, $lightness: -15%);
              color: #ffffff;
            }
          }
        }

      }
    }

    &__link {
      text-transform: uppercase;
    }
    ul.slick-dots{
      top: 50%;
      right: 20px;
      position: absolute;
      transform: translateY(-50%);
      width: 40px;
      li {
        display: block;
        button:before {
          font-size: 28px;
        }
      }
    }
  }
}

.slider__box {
  margin-bottom: rem-calc(16);
  .slides {
    margin: 0 30px;
    position: relative;
    .slider-button {
      border: 1px solid $light-gray;
      background-color: $white;
      color: $body-font-color;
      border-radius: 0;
      height: 100%;
      width: 28px;
      &.slider-prev {
        left: -30px;
      }
      &.slider-next {
        right: -30px;

      }
    }
  }
  .box-w {
    padding: rem-calc(0 8);
  }
  .box {
    border: 1px solid $light-gray;
    background-color: $white;

    &__image {

    }
    &__text {
      text-align: center;
      padding: rem-calc(20);
      color: $primary-color;
    }
    &__link {
      text-align: center;
    }
  }
}

.series-buttons {
  padding-top: rem-calc(6);
  .media-object {
    margin-bottom: rem-calc(6);
    padding: rem-calc(16);
  }
  &__image {
    img {
      max-width: 172px;
    }
  }
  &__description {
    padding-bottom: rem-calc(8);
  }
  &__buttons {

  }
}
.box-simple {
  & > .cell {
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }
  &__content {

    padding: rem-calc(14 80);

    &__image {
      img {
        max-height: 200px;
      }
    }
    &__description {
      padding: rem-calc(8 0);
    }
  }
}
.series-circle {
  border-bottom: 1px solid $light-gray;
  padding: 0 $global-padding;
  &__content {
    padding: rem-calc(0 20);
  }
  &__image {
    img {
      max-height: 200px;
    }
  }
}

.downloads {
  padding: rem-calc(20) $global-padding;
  &__description {
    font-size: rem-calc(18);
    text-align: justify;
  }
  &__list {
    list-style: none;
    li {
      border-top: 1px solid $light-gray;

      padding: rem-calc(6 0);
      &:first-child {
        border-top: none;
      }
      a {
        color: $secondary-color;
        &:hover {
          color: $primary-color;
        }
      }
      svg {
        width: 30px;
        height: auto;
        vertical-align: middle;
        path {
          fill: #622907;
        }
      }
      span {
        vertical-align: middle;
      }
    }
  }
}


.page-content--left {
  .series-buttons {
    .media-object {
      border-bottom: 1px solid $light-gray;
      @include breakpoint(large) {
        border-bottom: none;
        // border-right: 1px solid $light-gray;
      }
    }

  }
}

//.image-gallery {
//  &__item {
//    background-repeat: no-repeat;
//    background-size: cover;
//    margin-bottom: 4px;
//    &.text-left {
//      // margin-left: -2px;
//    }
//    &.text-right {
//      // margin-right: 0;
//    }
//  }
//  &__content {
//    &__image {
//
//      img {
//        visibility: hidden;
//      }
//    }
//  }
//  .text-left {
//    .image-gallery__content {
//      padding-right: 2px;
//    }
//  }
//  .text-center {
//    .image-gallery__content {
//      padding: 0 2px;
//    }
//  }
//  .text-right {
//    .image-gallery__content {
//      padding-left: 2px;
//    }
//  }
//}

.image-gallery {
  margin-left: -2px;
}

.home-timetables {
  background-color: #303030;
  color: $white;
  margin-top: -4px;
  padding: 20px 50px 10px 50px;
  h5 {
    font-weight: 500;
  }
  table {
    margin-bottom: 0;
  }
  tbody {
    background-color: #303030;
    border: none;
    td {
      padding: 0;
    }
  }
  .media-object {

  }
  .media-object-section:first-child {
    padding-right: 10px;
  }
  .padding-left-30 {
    padding-left: 0;
    @include breakpoint(large) {
      padding-left: 30px;
    }
  }
  .description {
   // max-height: 25px;
    p {
      margin-bottom: 0;
    }
  }
}

