@import "../../components/settings";
@import "../../components/mixins";

.teaser {
  &-text-image {
    background-color: $white;
    .text-w {
      a{

      }
      text-align: center;
      padding: rem-calc(20 16 10);
      width: 100%;
    }
    .descr {
      color: $primary-color;

      @include breakpoint(medium) {
        font-size: rem-calc(28);
        p {
          line-height: 1.2;
        }

      }

    }
    .image-w {
      height: 100%;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center center;
      }
    }
    .cell__text {
      position: relative;
    }
    .link-w {

    }
  }

  &-text-image-icon {
    background-color: $white;
    @include xy-gutters((
            small: 20px,
            medium: 40px
    ), padding, left right top bottom);
    .title-w {
      h3 {
        margin-bottom: 0;
        &.sub_title {
          font-weight: 300;
        }
      }
    }

    .text-w {

    }
    .descr {

    }
    .image-w {
      height: 100%;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center center;
      }
    }
    .cell__text {
      position: relative;
    }
  }

  &-text-image--green {
    overflow: hidden;
    @include headers{
      color: $white;
    }
    .text-w {
      @include xy-gutters((
              small: 40px,
              medium: 60px
      ), padding, left right top bottom);
      width: 100%;
    }
    .descr {
      color: $white;
      @include breakpoint(medium) {
        font-size: rem-calc(20);
        p {

        }

      }

    }
    .image-w {
      height: 100%;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center center;
      }
    }
    .cell__text {
      position: relative;
      background-color: #004d40;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 500px 30px 0 0;
        border-color: #004d40 transparent transparent transparent;
      }
    }
    .link-w {

    }
    &--long-text {
      .descr {
        font-size: rem-calc(16);
      }
    }
  }

  &__link  {
    background-color: $white;
    @include xy-gutters((
            small: 20px,
            medium: 40px
    ), padding, left right top bottom);

    a {
      @include breakpoint(large) {
        padding-left: rem-calc(70) !important;
        padding-right: rem-calc(70) !important;
      }

    }
  }

  &-text-icon {
    background-color: $white;
    .text-w {

      text-align: center;
      padding: rem-calc(20 16 10);
      width: 100%;
    }
    .descr {
      color: $primary-color;

      @include breakpoint(medium) {
        font-size: rem-calc(28);
        p {
          line-height: 1.2;
        }

      }

    }
    .image-w {
      height: 100%;
      background: {
        repeat: no-repeat;
        size: cover;
        position: center center;
      }
    }
    .cell__text {
      position: relative;
    }
    .link-w {

    }
  }

  &__simple {
    background-color: $white;
    padding: $global-padding;
    @include xy-gutters((
            small: 30px,
            medium: 64px
    ), padding, left right top bottom);

    .title-w {
      h2, h3 {

        font-weight: 300;
        b, strong {
          font-weight: 400;
        }
      }
    }
    .descr-w{
      margin-top: rem-calc(16);
    }

  }

  &__banner {
    .image-w {
      position: relative;
      .text-box {
        @include breakpoint(medium) {
          @include absolute-center;
          color: $white;
          width: 80%;
        }
        @include breakpoint(large) {
          width: auto;
        }
      }
    }
  }

  &__category_tab {
    position: relative;
    margin-bottom: 1px;
    .text-container {
      bottom: 20px;
      position: absolute;
      left: 20px;
      width: 60%;

      &__subtitle {
        background-color: $secondary-color;
        text-align: center;
        width: 32%;
        h3 {
          color: $white;
          font-size: 14px;
          font-weight: 700;
          line-height: 0.8;
          margin: 0;
          padding: rem-calc(10 0);
          text-align: center;
          text-transform: uppercase;
        }
      }
      &__box {
        background-color: $white;
        padding: rem-calc(10 16);
      }
      &__description {
        .box-logo {
          padding-bottom: rem-calc(10);
        }
        img {
          max-height: 70px;
        }
      }
    }
  }

  &__sidebyside {
    // padding-right: $global-padding;
    padding-top: 4px;
    .description {
      padding: 20px 30px;
    }
    @include breakpoint(medium) {
      padding-left: 30px;
      .description {
        padding-left: 0;
      }
    }
  }
  &__sbs_xs_img {
    padding-top: 4px;
    &__description {
      background-color: #eeeeee;
      border-left: 4px solid #ffffff;
    }
    .description {
      padding: 40px 20px;
      text-align: justify;
      @include breakpoint(medium) {
        padding: 70px 40px;
      }
    }
    .teaser-image {
      background-repeat: no-repeat;
      background-size: cover;
      img {
        visibility: hidden;
      }
    }
  }

  &__our-shop{
    padding-top: 15px;
    padding-bottom: 15px;
    @include breakpoint(medium) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .teaser-content{

      @include breakpoint(small only) {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          text-align: center;
        }
      }
      @include breakpoint(medium) {
        padding-top: 0;
        padding-left: 30px;
        padding-right: 0;
      }
      @include breakpoint(large) {
        padding-left: 40px;
      }
    }

  }
}
