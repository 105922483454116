@import "../../../../themes/static/themes/css/components/settings";
@import "../../../../themes/static/themes/css/components/mixins";



.ambassadors {
  table {
    tbody {
      tr{
        &:hover {
          background-color: $table-striped-background;
        }
      }
    }
  }
  &__list {
    .cell {
      border-right: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 1rem;
      li {
        label {
          display: inline-block;
        }
      }
    }
  }
}