@import "../components/settings";
@import "../components/mixins";

$menu-items-padding: rem-calc(10 6);
@import "../../../../../bower_components/foundation-sites/scss/components/menu";

.social {
  @include menu-base;
  &__link {
    display: block;
    opacity: 1;
    @include transition();
    &:hover {
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
  &__icon {
    display: block;
    width: 30px;
    height: 30px;
    svg {
      path {
        fill: $medium-gray;
        @include transition(all, 120ms);
      }
    }
  }
}

.share-link {
  display: inline-block;
  padding: rem-calc(4 4 4 30);
  position: relative;
  img{
    position: absolute;
    left: 0;
    width: 22px;
  }
}