*:focus {
  outline: none;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

blockquote {
  font-family: Georgia, serif;
  font-size: 18px;
  font-style: italic;
  width: 100%;
  margin: 0.25em 0;
  padding: 0.25em 45px;
  line-height: 1.45;
  position: relative;
  color: #383838;
  border-left: none;
  &::before {
    display: block;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -10px;
    top: -20px;
    color: #7a7a7a;
  }
  &::after {
    display: block;
    content: "\201D";
    font-size: 80px;
    position: absolute;
    right: 30px;
    bottom: -80px;
    color: #7a7a7a;
  }
}

.lightcase-icon-pause, .lightcase-icon-play {
  display: none !important;
  opacity: 0 !important;
}

.reveal {
  // top: rem-calc(70)  !important;
  &__content {
    padding-top: rem-calc(30);
    position: relative;
    .modal-content {
      background-color: $white;
      border: 1px solid $medium-gray;
      padding: $global-padding;
      &.wide {
        padding: 0;
      }
    }
    .close-button {
      top: -4px;
      &__text {
        font-size: rem-calc(12);
        vertical-align: middle;
      }
    }
    .share-button-group {
      top: 0;
      right: rem-calc(16);
      color: $white;
      position: absolute;
      .share-button {
        color: $white;
        svg {
          path {
            fill: $white;
          }
        }
        span {
          font-size: rem-calc(12);
          vertical-align: super;
        }
      }
    }
  }
}
.alert-message {
  padding: rem-calc(30);
}
.filter-label {
  min-width: 100px;
}
hr {
  margin: 0;
  padding: 0;
  border-color: $light-gray;
}

.share-page {
  text-align: right;
  @include breakpoint(large) {
    position: absolute;
    right: rem-calc(15);
    top: rem-calc(7);
  }
  span {
    font-size: 1rem;
    vertical-align: middle;
  }
  i {
    svg {
      vertical-align: middle;
      height: rem-calc(20);
      width: rem-calc(20);
      fill: $white;
    }
  }
  a {
    color: $white;

    &:hover {
      color: $body-font-color;
      svg {
        fill: $body-font-color;
      }
    }
  }





}