@import "../components/mixins";

.animate{
  &--fade-botton{
    @include transition(all, 200ms, ease, 300ms);
    transform: translateY(30px);
    opacity: 0;

    &.no-small-animation {
      transform: translateY(0);
      opacity: 1;
      @include breakpoint(medium) {
        transform: translateY(30px);
        opacity: 0;
      }
    }

    &.on{
      transform: translateY(0);
      opacity: 1;
    }
  }
}