$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors-dark ($duration*4) ease-in-out infinite;
}

.path-white {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
 animation: dash $duration ease-in-out infinite,
    colors-clean ($duration*4) ease-in-out infinite;
}


@keyframes colors-dark {
	0% { stroke: #7a5163; }
	25% { stroke: #7a5163; }
	50% { stroke: #7a5163; }
	75% { stroke: #7a5163; }
    100% { stroke: #7a5163; }
}

@keyframes colors-clean {
	0% { stroke: #fff; }
	25% { stroke: #fff; }
	50% { stroke: #fff; }
	75% { stroke: #fff; }
    100% { stroke: #fff; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}