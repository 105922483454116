@import "../components/settings";
@import "../components/mixins";

header {
  position: relative;
  @include breakpoint(large) {

  }
}


.large-header {

}

.page-title {
  background-color: $primary-color;
  color: $white;
  font-size: rem-calc(20);
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  padding-right: rem-calc(10);
  position: relative;
  @include breakpoint(large) {
    padding-right: $global-padding;
  }
  h1 {
    font-size: rem-calc(20);
    margin-bottom: 0;
  }
}

.col-logo {
  background-color: $bg-logo;
  position: relative;
  padding: rem-calc(20 0 18 0);
  z-index: 100;
}
.col-menu {
  background-color: $bg-menu;
  color: $white;
  z-index: 100;
}

.top-nav {
  background-color: $secondary-color;
  color: $white;

  &__logo {
    display: block;
    svg {
      height: 60px;
    }
    @include transition(all, 400ms);
    @include breakpoint(medium) {
      height: 70px;
      svg {
        height: 70px;
      }
    }
    @include breakpoint(large) {
      height: 90px;
      svg {
        height: 90px;
      }
    }
  }
  &__services {
    text-transform: uppercase;
    i {
      display: inline-block;
      margin-right: rem-calc(10);
      vertical-align: middle;
    }
    a {
      color: $white;
      display: block;
      font-size: rem-calc(12);
      padding-top: rem-calc(36);
      @include transition(all, 120ms);
      svg {
        height: 22px;
        path {
          fill: $white;
          @include transition(all, 120ms);
        }
      }
      img.service_ico {
        height: 32px;
        position: relative;
        top: -4px;
      }
      &.mail, &.selected-lang {
        padding-top: rem-calc(40);
        svg {
          height: 16px;
        }
      }
      &.newsletter {
        svg {
          fill: $white;
          height: 20px;
        }
      }
      &.evidence {
        background-color: $primary-color;
        margin-left: 4px;
        padding-top: rem-calc(28);
      }
      &.enoturism.evidence {
        background-color: #c17730;
        padding-top: rem-calc(24);
        img.service_ico {
          height: 36px;
        }
      }
      &:hover, &:current {
        color: $primary-color;
        svg {
          path {
            fill: $primary-color;
          }
        }
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
// language menu
.langs {
  position: relative;
  &__current {
    a.selected-lang {
      @include transition();
      display: block;

    }
  }
  &__list {
    position: absolute;
    top: 60px;
    right: 0;  // 4px;

    background-color: $white;  // rgba(255, 255, 255, 0.9);
    min-width: 100px;
    padding: 10px;
    overflow: hidden;
    text-align: center;
    @include transition();
    opacity: 0;
    a {
      color: $secondary-color;
      padding: rem-calc(4 0);
    }
    &.on {
      opacity: 1;
      z-index: 999999;
    }
    .align-left {
      li {
        justify-content: left;
      }
    }
  }
}

.main-menu {
  padding-top: rem-calc(18);
}
.nav {
  .openable {
    opacity: 0;
    visibility: hidden;
    @include transition(all, 100ms);
    &.opened {
      visibility: visible;
      opacity: 1;
      @include transition();
    }
  }
  &--primary {
    margin-right: 8px;
    position: relative;
    &__item {
      padding-left: 1rem;
      padding-right: 1rem;

      >a {
        display: inline-block;
        padding: rem-calc(10 2 26 2);
        position: relative;
      }
      >a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        width: 0;
        height: 10px;
        background-color: $primary-color;
        @include transition(width, 150ms, ease);
      }
      &.current, &:hover {

        > a:after {
          width: 100%;
        }
      }
      svg {
        height: 10px;
        path {
          fill: $white;
        }
      }
      &.ddm-parent {
        position: relative;
      }

      // dropdown
      .dd-wrapper {
        position: absolute;
        z-index: 30;
        left: -12px;
        top: 100%;  // calc(100% + 12px);
        border: 1px solid $primary-color;
        background-color: $primary-color;
        .dd {
          margin: 0;
          padding: rem-calc(16);

          .dropdown {
            max-width: 400px;
            min-width: 220px;
          }

          li {
            border-top: 1px solid #d5d096;
            > ul  {
              animation-duration: 500ms;
              padding: rem-calc(20 0);
            }
            &.current {
              > a {
                // background: none;
              }
            }
            &.is-active {
              > .level-1 {

              }
            }
            .level-1 {

            }

            &.has-children {

              > a {
                &::after {
                  font-size: 8px;
                  line-height: normal;
                  top: 15px;
                  right: 12px;
                }
              }
              .submenu {
                background: #f6f1e9;
                margin-left: 1px;

              }
            }
            a {
              color: $white;
              position: relative;
              padding: rem-calc(6 28 6 8);
              font-weight: 500;
              font-size: rem-calc(17);
              white-space: nowrap;
              width: 100%;
              &::before {
                content: '\e005';
                font-family: $icon-monstr;
                position: absolute;
                right: 4px;
                top: 8px;
              }
              &.ext-link {
                svg {
                  width: 24px;
                  height: 20px;
                  position: absolute;
                  top: 4px;
                  right: 2px;
                }
                &::before {
                  content: '';
                }
              }
              &:hover {
                background-color: $white;
                color: $primary-color;
                svg {
                  path {
                    fill: $primary-color;
                  }
                }
              }
            }
            &:first-child {
              border-top: none;
            }
          }
          .align-left {
            li {
              justify-content: left;
            }
          }
        }
      }
    }
    &__link {
      color: $white;
      text-transform: uppercase;
      &:hover, &:focus {
        color: $white;
      }
    }
  }

}



.nav {
  // MEGA MENU
  .mega-wrapper {
    //display: none;
    position: absolute;
    z-index: 30;
    //width: 100%;
    left: 10px;
    right: 10px;
    top: 100%;
    .mega {
      background-color: $primary-color;
      border-top: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
      border-bottom-color: $dark-gray;
      border-bottom-width: rem-calc(10);
      overflow: hidden;
      padding: rem-calc(10 0);
      .has-sep {
        border-right: 1px solid $light-gray;
      }

      &__in {
        padding: rem-calc(20);
      }
      &__link {
        font-size: rem-calc(20);
        font-weight: 500;
        min-height: rem-calc(80);
        padding: rem-calc(10 0);
        text-transform: uppercase;
      }
      &__child {
        a {
          color: $white;
          line-height: 1.5;
          padding: rem-calc(2 22);
          position: relative;
          text-align: center;
          &::before {
            content: '\e005';
            font-family: $icon-monstr;
            position: absolute;
            left: 4px;
            top: 3px;
          }
          &:hover {
            background-color: $white;
            color: $primary-color;
          }
        }
      }
    }

    // USELESS EXCEPTION
    .focus-on {
      margin-top: rem-calc(24);
      display: inline-block;
      color: $primary-color;
      border: 1px solid $light-gray;
      border-radius: 30px;
      padding: rem-calc(10 10 10 42);
      position: relative;
      font-size: rem-calc(15);
      span {
        font-weight: 400;
        font-size: rem-calc(14);
      }
      img {
        position: absolute;
        left: 0;
        top: -3px;
        width: 40px;
      }
    }
  }

}


// MOBILE BAR
.mobile-bar-wrapper {
  background-color: $primary-color;
  animation-duration: 300ms;
  position: fixed;
  bottom: -2px;
  left: 0;
  z-index: 600;
  width: 100%;
  box-shadow: 0px -4px 22px -9px rgba(0, 0, 0, 0.75);
  .mobile-bar {
    &__item {
      padding: 16px 40px;
      display: block;
      margin: 0 auto;
      &:hover {
        // background-color: #f4f4f4;
        color: #ffffff;
      }
      &--opener {
        .mobile-bar__icon--closer {
          display: none;
        }
        &.is-active {
          .mobile-bar__icon--closer {
            display: block;
          }
          .mobile-bar__icon--hamb {
            display: none;
          }
        }
      }

    }
    &__icon {
      display: block;
      margin: 0 auto;
      width: 24px;
      height: 24px;

      svg {
        fill: $white;
      }
    }
  }
}

// MOBILE MENU
.small-menu-panel {
  opacity: 0;
  position: fixed;
  overflow: auto;
  height: 100%;
  width: 300px;
  right: 0;
  top: 0;
  z-index: 500;
  background-color: #ffffff;
  //transform: translateX(0);
  transform: translateX(100%);
  transition: transform ease-out 200ms;
  @include breakpoint(medium down) {
    opacity: 1;
  }
  @include breakpoint(large) {
    display: none;
  }
  &.is-active {
    transform: translateX(0);
  }
  .logo {
    width: 120px;
    margin: 18px auto;
  }
  .user {
    text-align: center;
    font-size: rem-calc(12);
    margin-bottom: rem-calc(6);
  }
  .small-home-icon {
    width: 24px;
    height: 25px;
    margin: 20px 0 0 16px;

  }
  .small-menu-user {

    i {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .small-menu-section {
    margin-bottom: rem-calc(20);
    .sep {
      margin: rem-calc(20 16);
      border-bottom: 1px solid $light-gray;
    }
    p {
      padding: .875rem 1rem;
    }
    .langs {
      a {
        padding: rem-calc(0 0 0 16);
      }
    }
    i {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
    &.section-logo {
      background-color: $secondary-color;
      padding: rem-calc(60 0 18 0);
      margin: 0 auto;
    }
  }
  .small-closer {
    padding: rem-calc(10 20);
    color: #000;
    font-size: 26px;
    display: block;
    font-weight: 700;
  }
  .small-menu {
    // FORCE CURRENT NODE OPENED
    border-top: 1px solid $light-gray;
    .is-active.invisible {
      visibility: visible;
    }
    li {
      &.active {
        a {
          background-color: $white;
          color: $primary-color;
        }
      }
    }

    a {
      color: $white;
      //font-weight: 400;
      font-size: rem-calc(18);
      position: relative;
      display: block;
      box-sizing: border-box;
      border-bottom: 1px solid $light-gray;
      background-color: $primary-color;
      img {
        width: 24px;
        margin-right: rem-calc(4);
        vertical-align: middle;
      }
      &:hover {
        background-color: $white;
        color: $dark-gray;
        &::before {
          border-color: transparent $dark-gray transparent transparent;
        }
        &::after {
          border-color: transparent transparent transparent $dark-gray;
        }
      }
      &::after {

      }
      &.evidence {
        &:hover {
          background-color: #c17730;
          color: white;
        }
      }
    }
    &__service {
      &.top-border {
        border-top: 2px solid $white;
      }
      a {
        svg {
          height: 22px;
          path {
            fill: $white;
            @include transition(all, 120ms);
          }
        }
        &.mail {
          svg {
            height: 16px;
          }
        }
        &:hover, &:active {
          svg {
            path {
              fill: $primary-color;
            }
          }
        }
      }
    }
  }
}

.breadcrumbs-nav {
  background-color: $white;
  .breadcrumbs {
    margin-left: $global-padding;
  }
  li {
    font-weight: 700;
  }
  a {
    font-weight: 400;
    &:hover {
    }
  }
}

//.modal-langs {
//  max-width: 830px;
//
//}
//
//.language-switcher {
//  padding: rem-calc(0);
//
//  a {
//    display: block;
//    padding: rem-calc(4 16);
//    border: 1px solid transparent;
//    border-radius: 20px;
//    @include transition();
//    &:hover, .active {
//      border: 1px solid $light-gray;
//
//    }
//  }
//  img {
//    display: inline-block;
//    margin-right: rem-calc(4);
//    max-width: 26px;
//    vertical-align: text-top;
//  }
//}
