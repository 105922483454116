@import '../../../../../bower_components/foundation-sites/scss/util/util';

@mixin headers(){
  @for $i from 1 through 6 {
    h#{$i} {
      @content;
    }
  }
}

@mixin ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin transition($prop: all, $duration: 200ms, $timing: linear, $delay: 0ms) {
  transition: {
    property: $prop;
    duration: $duration;
    timing-function: $timing;
    delay: $delay;
  }
}

@mixin triangle($width, $height, $color, $direction){
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  transform: rotate(360deg);

  @if ($direction == down) {
    border-color: $color transparent transparent;
    border-width: $height ($width/2) 0 ($width/2);
  }
  @if ($direction == up) {
    border-color: transparent transparent $color;
    border-width: 0 ($width/2) $height ($width/2);
  }
  @if ($direction == right) {
    border-color: transparent transparent transparent $color;
    border-width: ($height/2) 0 ($height/2) $width;
  }
  @if ($direction == left) {
    border-color: transparent $color transparent transparent;
    border-width: ($height/2) $width ($height/2) 0;
  }
}

@mixin blender($blend-color: #000000, $blend-opacity: 0.7){
  &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparentize($blend-color, $blend-opacity);
      @content;
    }
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin transition-multi($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  transition: $unfoldedTransitions;
}




$spacer-small: rem-calc(10);
$spacer-medium: rem-calc(4);

@mixin space($position) {
  @if ($position == up) {
    margin-top: $spacer-small;
    @include breakpoint(medium) {
      margin-top: $spacer-medium;
    }
  }
  @if ($position == down) {
    margin-bottom: $spacer-small;
    @include breakpoint(medium) {
      margin-bottom: $spacer-medium;
    }
  }
}
